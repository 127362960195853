import React from 'react';
import styles from './Blueprint.module.css';
import blueprintImg from '../../assets/71e92de6-85e8-42de-a38d-1c3d06775e72.gif';

const blueprint = props => (
    <div className={styles.Blueprint}>
      <img alt="planta coliseu" src={blueprintImg} />
    </div>
);

export default blueprint;
