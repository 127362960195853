import React from 'react';
import logo from '../../assets/logo.png';
import styles from './Header.module.css';

export default function header() {
    return (
        <div className={styles.Header}>
          <img src={logo} alt="Logo Coliseu Porto Ageas"/>
        </div>
    )
}
