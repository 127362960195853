import React from 'react';
import {observer} from 'mobx-react';
import 'moment/locale/pt';
import Separator from './Separator.js';
import Event from './Event.js';

const EventPreview = props => {
    const isSeparator = props.obj.get('separator');
    return isSeparator ? <Separator event={props.obj}/> : <Event event={props.obj}/>;
};

export default observer(EventPreview);


