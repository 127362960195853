import React, {Component} from 'react';
import styles from './Progress.module.css';
import moment from 'moment';

class Progress extends Component {

    getMonthName = (month) => {
        moment.locale('pt');
        let name = moment(month, 'M').format('MMMM');
        return name.charAt(0).toUpperCase() + name.slice(1);
    }

    matchFunc = (active, month) => {
        return active.month === month.month && active.year === month.year;
    }

    renderMonth = month => {
        let classes = this.props.active.find(active => this.matchFunc(active, month)) ? styles.Active : '';
        return <span key={Math.random()} className={classes}>{this.getMonthName(month.month)}</span>;
    }

    renderMonths = () => {
        return (
            <div className={styles.Months}>
              {this.props.months.map(month => this.renderMonth(month))}
            </div>
        );
    }

    render() {
        let width = this.props.percent + '%';
        let visibility = this.props.visibility ? 'visible' : 'hidden';
        return (
            <div className={styles.Progress}>
              {this.renderMonths()}
            <div className={styles.ProgressBar} style={{width: width, visibility: visibility}} />
            </div>
        );
    }
}

export default Progress;
