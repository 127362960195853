import React, {Component} from 'react';
import classNames from 'classnames';
import styles from './NextShows.module.css';

class NextShows extends Component {

    texts = [
        'Próximos eventos',
        'Next Shows',
        'Prochains spectacles'
    ];

    tempo = 8000

    state = {
        id: 0,
        translate: 0
    }

    resetTranslate = () => {
        this.setState({translate: 0});
    }

    getText = () => {
        if(this.state.id < (this.texts.length - 1)) {
            this.setState({translate: 1, id: this.state.id + 1});
        } else {
            this.setState({translate: 1, id: 0});
        }
        window.setTimeout(this.resetTranslate, 1000);
    }

    componentDidMount() {
        this.id = window.setInterval(this.getText, this.tempo);
    }

    render() {
        let classes = null;
        if(this.state.translate === 1) {
            classes = classNames(styles.NextShows, styles.Test);
        } else {
            classes = classNames(styles.NextShows);
        }
        return (
            <div className={classes}>
              {this.texts[this.state.id]}
            </div>
        );
    }
}

export default NextShows;
