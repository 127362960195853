import React from 'react';
import styles from './EventPreview.module.css';
import Moment from 'react-moment';
import {observer} from 'mobx-react';
import 'moment/locale/pt';
import classNames from 'classnames';

// https://stackoverflow.com/a/37096512
const Duration = props => {
    let seconds = Number(props.seconds);
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor(seconds % 3600 / 60) !== 0 ? Math.floor(seconds % 3600 / 60) : '00';
    return (
        <span>{hours + 'h' + minutes}</span>
    );
};

const Newline2Break = props => {
    const {text} = props;
    return (
        <>
          {text.split('\n').map((item, key) => {
              return <p key={key}>{item}<br/></p>;
          })}
        </>
    );
};

const EventState = props => {
    const state = props.event.get('state');
    let marker = '';
    switch(state) {
    case 'SOLDOUT':
        marker = 'ESGOTADO';
        break;
    case 'POSTPONED':
        marker = 'ADIADO';
        break;
    case 'CANCELED':
        marker = 'CANCELADO';
        break;
    default:
        marker = '';
    }
    return (
        <p className={styles.NonActiveMarker}>{marker}</p>
    );
};

const Event = props => {
    const {event} = props;
    const nonActive = event.get('state') !== 'ACTIVE';
    return (
        <div className={classNames(styles.Event, {[styles.EventNonActive]: nonActive})}>
          <p className={styles.EventName}>
            {event.get('name')}
          </p>
          <p className={styles.EventDate}>
            <Moment locale="pt" format="ddd DD MMMM">
              {event.get('start_date')}
            </Moment>
          </p>
          <p className={styles.EventTime}>
            <Moment locale="pt" format="HH[h]mm">
              {event.get('start_date')}
            </Moment>
          </p>
          <EventState event={event}/>
          <div className={styles.Info}>
            <p>{event.room.get('name')}</p>
            <p>Classificação etária: {event.get('minimum_age')}</p>
            <p>Duração aproximada: <Duration seconds={event.get('estimated_duration')}/></p>
            <p>Abertura de Portas: {event.get('event_opening')}</p>
            {event.get('occupancy') && <p>Lotação: {event.get('occupancy')} lugares</p>}
            <p>Promotor: {event.promoter.get('name')}</p>
          </div>
          {event.get('tickets_info') &&
           <div className={styles.Info}>
             <p>Bilhetes</p>
             <Newline2Break text={event.get('tickets_info')}/>
           </div>
          }
          {event.get('discounts_info') &&
           <div className={styles.Info}>
             <p>Descontos</p>
             <Newline2Break text={event.get('discounts_info')}/>
           </div>
          }
        </div>
    );
};

export default observer(Event);
