import {Model} from './base/Model';
import Collection from "./base/Collection";

export class Room extends Model {
    urlRoot() {
        return "/rooms/";
    }
}

export class Rooms extends Collection {

    urlRoot() {
        return "/rooms/";
    }

    model() {
        return Room;
    }
}
