import {Model} from './base/Model';
import {decorate, computed} from 'mobx';
import Collection from "./base/Collection";
import {Room} from './RoomsStore';
import {Promoter} from './PromotersStore';
import moment from 'moment';

export class Event extends Model {
    urlRoot() {
        return "/events/";
    }

    get room() {
	const data = new Room({id: this.get('room')})
        data.fetch()
        return data
    }

    get promoter() {
	const data = new Promoter({id: this.get('promoter')})
        data.fetch()
        return data
    }
}

decorate(Event, {
    room: computed,
    promoter: computed,
})


export class Events extends Collection {

    is_archived = 0
    ordering = 'start_date';
    last_update = null;
    tours_category = 28

    constructor(page_size) {
	super();
	this.page_size = page_size;
    }

    urlRoot() {
        return "/events/";
    }

    model() {
        return Event;
    }

    fetch = () => {
	this.last_update = new Date();
	this.models = [];
	const data = {ordering: this.ordering, page_size: this.page_size, is_archived: this.is_archived, category__not_in: this.tours_category}
	return super.fetch(data);
    }

    get months() {
	let months = [];
	if(this.scroll.length > 0) {
	    this.scroll.map(event => {
		const newMonth = moment(event.get('start_date')).format('M');
		const newYear = moment(event.get('start_date')).format('YYYY');
		if(!months.find(obj => obj.month === newMonth && obj.year === newYear)) {
		    months.push({month: newMonth, year: newYear});
		}
		return null;
	    });
	}
	return months;

    }

    get full() {
	let fullEvents = [];
	if(this.models.length > 0) {
	    this.models.map(event => {
		moment.locale('pt');
		const year = moment(event.get('start_date')).format('YYYY');
		const month = moment(event.get('start_date')).format('M');
		let name = moment(event.get('start_date')).format('MMMM');
		name = name.charAt(0).toUpperCase() + name.slice(1);

		if(!fullEvents.find(event => (event.get('month') === month && event.get('year') === year))) {
		    fullEvents.push(new Event({id: Math.random(), name: name, month: month, year: year, separator: true}));
		}

		fullEvents.push(event)
		return null;
	    });
	}
	return fullEvents;
    }

    get fixed() {
	if(this.full.length > 0) {
	    this.slice_at = 12
	    let borderEvent = this.full[this.slice_at];
	    if(borderEvent.get('separator')) {
		this.slice_at = 11;
	    }
	    return this.full.slice(0, this.slice_at);
	}
	return []
    }

    get scroll() {
	return this.full.slice(this.slice_at);
    }

    get count() {
	return this.full.length;
    }
}

decorate(Events, {
    full: computed,
    fixed: computed,
    scroll: computed,
    months: computed,
    count: computed,
})
