import {Model} from './base/Model';
import Collection from "./base/Collection";

export class Promoter extends Model {

    urlRoot() {
        return "/promoters/";
    }

}

export class Promoters extends Collection {

    urlRoot() {
        return "/promoters/";
    }

    model() {
        return Promoter;
    }
}
