import React from 'react';
import styles from './Separator.module.css';
import 'moment/locale/pt';

const Separator = props => {
    const index = 'Month' + props.event.get('month');
    const classes = [styles.Separator, styles[index]].join(' ');
    return (
        <div className={classes}>
          <h2>{props.event.get('name')}</h2>
          <h2 className={styles.SeparatorYear}>{props.event.get('year')}</h2>
        </div>
    );
};

export default Separator;
