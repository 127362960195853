import React, { Component } from 'react';
import {observer} from 'mobx-react';
import Header from './components/Header/Header';
import EventsGrid from './components/EventsGrid/EventsGrid';
import Blueprint from './components/Blueprint/Blueprint';
import NextShows from './components/NextShows/NextShows';
import styles from './App.module.css';

class App extends Component {

    state = {
        progress: 50
    }

    render() {
        return (
	    <div className={styles.App}>
	      <Header/>
              <NextShows />
              <EventsGrid limit={9999}/>
              <Blueprint/>
	    </div>
	);
    }
}

export default observer(App);
