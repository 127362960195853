import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {Events} from '../../services/EventsStore';
import EventPreview from '../EventPreview/EventPreview';
import Progress from '../Progress/Progress';
import styles from './EventsGrid.module.css';
import moment from 'moment';

class EventsGrid extends Component {

    constructor(props) {
        super(props);
        this.events = new Events(this.props.limit);
        this.events.fetch();
        this.row_width =  360; // this must match the event and separator css
        this.per_row = 6;
        this.translateIsActive = true;
        this.active = [];
        this.loading = false;
        this.lastUpdate = new Date();
        this.updateInterval = 60 * 60 * 1000; // minutes in ms, default is 60 * 60 * 1000
        this.progressIncrement = 0.85; // default is 0.85, the amount the progress bar is increment in each cylce
        this.state = {
            classes: [styles.EventsGrid],
            translate: 0,
            page: 1,
            direction: -1,
            pace: 9000, // default 9000, pace and reading_time are related
            transition_duration: 1000, // default is 1000 this must match the css
            progress: 0,
            showProgress: true,
            active: [],

        };
    }

    componentDidMount() {
        this.start();
    }

    start = () => {
        if(this.translateIsActive) {
            this.translateId = window.setInterval(this.translateEvents, this.state.pace);
        }
    }

    stop = () => {
        window.clearInterval(this.translateId);
        window.clearInterval(this.updateProgressId);
    }

    fetchFromApi = () => {
        window.setTimeout(() => this.loading = true, 6000);
        this.events.page_size = 9999;
        window.setTimeout(() => this.events.fetch().then(
            response => {
                this.lastUpdate = new Date();
                this.loading = false;
                this.start();
            }), 7000);
    }

    updateIsRequired = () => {
        // check if update is needed
        const diff = new Date() - this.lastUpdate;
        return diff > this.updateInterval;
    }

    refresh = () => {
        this.stop();
        // check if update is needed
        if (this.updateIsRequired()) {
            this.fetchFromApi();
        } else {
            this.start();
        }
    }

    setProgress = () => {
        let reading_time = 65; // default reading of 65
        this.updateProgressId = window.setInterval(this.updateProgress, reading_time);
    }

    clearProgress = () => {
        window.clearInterval(this.updateProgressId);
        this.setState({progress: 0});
    }

    updateProgress = () => {
        this.setState({progress: this.state.progress + this.progressIncrement});
    }

    componentWillUnmount() {
        this.stop();
    }

    translateEvents = () => {
        this.clearProgress();
        window.setTimeout(this.setProgress, this.state.transition_duration);
        this.setState({
            translate: this.state.translate + (this.state.direction * this.row_width),
            page: this.state.page - this.state.direction}, this.updatePage);
        this.setActiveMonths();
    }

    updatePage = () => {
        if(this.state.page === (this.events.scroll.length - 4)) {
            this.resetTranslate();
        }
    }

    resetTranslate = () => {
        this.refresh();
        this.setState({translate: 0, page: 1, direction: -1});
    }

    getActiveAttrs = event => {
        if(!event) {
            console.log('something went wrong');
            return {};
        }
        if(event.get('separator')) {
            return {month: event.get('month'), year: event.get('year')};
        } else {
            const year = moment(event.get('start_date')).format('YYYY');
	    const month = moment(event.get('start_date')).format('M');
            return {month, year};
        }
    }

    setActiveMonths = () => {
        if(this.events.scroll.length > 0) {
            let startIndex = 0 + (this.state.page - 1);
            let endIndex = startIndex + (this.per_row - 1);
            if(endIndex >= this.events.scroll.length) {
                endIndex = this.events.scroll.length - 1;
            }
            let startEvent = this.events.scroll[startIndex];
            let endEvent = this.events.scroll[endIndex];
            let start = {...this.getActiveAttrs(startEvent)};
            let end = {...this.getActiveAttrs(endEvent)};

            let monthsList = [...this.events.months];
            let startMonthIndex = monthsList.findIndex(obj => obj.month === start.month && obj.year === start.year);
            let endMonthIndex = monthsList.findIndex(obj => obj.month === end.month && obj.year === end.year);

            this.active = monthsList.slice(startMonthIndex, endMonthIndex + 1);
        }
    }

    render() {
        if(this.loading) {
            return <div className={styles.Loading}>Em Atualização... </div>;
        }
        let translate = 'translateX('+this.state.translate+'px)';
        let css = {};
        css.transform = translate;
        return (
            <>
              <div className={styles.FixedEventsContainer}>
                <div className={styles.EventsGrid}>
                  {this.events.fixed.map(evt => <EventPreview key={evt.get('id')} obj={evt}/>)}
                </div>
              </div>
              <Progress
                visibility={this.state.showProgress}
                percent={this.state.progress}
                months={this.events.months}
                active={this.active}
              />
              <div className={styles.ScrollEventsContainer}>
                <div className={styles.EventsGrid} style={css}>
                  {this.events.scroll.map(evt => <EventPreview key={evt.get('id')} obj={evt}/>)}
                </div>
              </div>
            </>
        );
    }
}

export default observer(EventsGrid);
